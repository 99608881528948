import React from "react";
import {
  HStack,
  NumberInput,
  NumberInputField,
  Button,
  Tooltip,
  Switch,
  Text,
  Box,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  80% { transform: scale(1); }
  90% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const Betting = ({
  isLoading,
  betAmount,
  handleBetAmountChange,
  startNewGame,
  maxBet,
  betMaxEnabled,
  setBetMaxEnabled,
}) => {
  // Compute validation directly
  const isBetAmountValid = betAmount > 0 && betAmount <= maxBet;

  // Tooltip message for invalid input
  const tooltipMessage =
    !isBetAmountValid && !isNaN(maxBet)
      ? `Bet between .001 and ${parseFloat(maxBet)}`
      : "";

  return (
    !isLoading && (
      <HStack align="center" spacing={2} pt={4} px={2} wrap="wrap">
        <Tooltip
          label={tooltipMessage}
          isOpen={!isBetAmountValid}
          hasArrow
          bg="red.600"
          color="white"
          placement="top"
        >
          <NumberInput
            value={betAmount}
            step={0.001}
            max={parseFloat(maxBet)}
            min={0.001}
            defaultValue={1}
            precision={3}
            size="lg"
            variant="outline"
            color="white"
            w="130px"
            onChange={(valueString) => handleBetAmountChange(valueString)}
          >
            <NumberInputField />
          </NumberInput>
        </Tooltip>

        <Button
          onClick={startNewGame}
          size="lg"
          width="85px"
          color="white"
          _hover={{ transform: "scale(1.02)" }}
          isDisabled={!isBetAmountValid || isLoading}
          variant="outline"
        >
          <Box
            as="span"
            animation={`${pulseAnimation} 5s infinite`}
            display="inline-block"
          >
            Bet
          </Box>
        </Button>
        <HStack w="130px">
          <Switch
            isChecked={betMaxEnabled}
            onChange={(e) => setBetMaxEnabled(e.target.checked)}
            colorScheme="green"
            size="lg"
            isDisabled={isLoading}
          />
          <Text fontSize="lg" color="white">
            Bet Max
          </Text>
        </HStack>
      </HStack>
    )
  );
};

export default Betting;
