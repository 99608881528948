// src/Blackjack.js
import React, { useState, useCallback, useRef, useEffect } from "react";
import { VStack, Box, IconButton, Text } from "@chakra-ui/react";
import { ethers } from "ethers";
import Confetti from "react-confetti";
import { FaCompress, FaExpand } from "react-icons/fa";
import useColorScheme from "./hooks/useColorScheme";
import useFetchGameData from "./hooks/useFetchGameData";
import useCardAnimation from "./hooks/useCardAnimation";
import { fetchBalance, executeContractFunction } from "./utils/ethersUtils";
import { blackjackAddress, provider } from "./constants";
import calculateHandValues from "./utils/calculateHandValues";
import DealerHand from "./components/DealerHand";
import PlayerHands from "./components/PlayerHands";
import Betting from "./components/Betting";
import AccountSelector from "./components/AccountSelector";
import WelcomeScreen from "./components/WelcomeScreen";
import { accounts } from "./constants/accounts";

const Blackjack = ({ addAlert, fullscreen, toggleFullscreen }) => {
  const delay = 350;
  const isUpdatingRef = useRef(false);
  const needsUpdateRef = useRef(false);

  const [showElements, setShowElements] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElements(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  // State declarations
  const [playerAddress, setPlayerAddress] = useState(() => {
    const stored = localStorage.getItem("playerAddress");
    if (stored) return stored;
    const rand = accounts[Math.floor(Math.random() * accounts.length)].address;
    localStorage.setItem("playerAddress", rand);
    return rand;
  });
  const [dealerBankroll, setDealerBankroll] = useState("0");
  const [playerBankroll, setPlayerBankroll] = useState("0");
  const [showLabel, setShowLabel] = useState(false);
  const [showGameActions, setShowGameActions] = useState(false);
  const [showBetButton, setShowBetButton] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [confettiRecycle, setConfettiRecycle] = useState(false);
  const [betAmount, setBetAmount] = useState(() =>
    parseFloat(localStorage.getItem("betAmount") || "1")
  );
  const [loadingAction, setLoadingAction] = useState("");
  const [showGameStatus, setShowGameStatus] = useState(false);
  const [showPayoutMessage, setShowPayoutMessage] = useState(false);

  // **1. Add state for Bet Max toggle**
  const [betMaxEnabled, setBetMaxEnabled] = useState(false);

  // Custom hooks
  const { gameData, setGameData, fetchGameData, isLoading, setIsLoading } =
    useFetchGameData(playerAddress, addAlert);
  const { maxBet, totalEscrow } = gameData;
  const [colorScheme, setNewColorScheme] = useColorScheme();

  // Initialize cardShown state
  const initializeCardShown = useCallback(
    () => ({
      player: (gameData.playerHands || []).map((hand) =>
        (hand || []).map(() => false)
      ),
      dealer: (gameData.dealerHand || []).map(() => false),
    }),
    [gameData.playerHands, gameData.dealerHand]
  );

  const [cardShown, setCardShown] = useState({
    player: [],
    dealer: [],
  });

  const [lastFlippedCardIndex, setLastFlippedCardIndex] = useState({});

  // Refs to track previous states
  const cardShownRef = useRef(cardShown);
  const lastFlippedCardIndexRef = useRef(lastFlippedCardIndex);
  const previousGameDataRef = useRef(null); // To track previous gameData

  // Update refs when state changes
  useEffect(() => {
    cardShownRef.current = cardShown;
  }, [cardShown]);

  useEffect(() => {
    lastFlippedCardIndexRef.current = lastFlippedCardIndex;
  }, [lastFlippedCardIndex]);

  // Animated hands state
  const [animatedPlayerHands, setAnimatedPlayerHands] = useState([]);
  const [animatedDealerHand, setAnimatedDealerHand] = useState([]);

  const { flipHandsSequentially } = useCardAnimation(
    gameData,
    cardShown,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setLastFlippedCardIndex
  );

  // **2. Synchronize betAmount with maxBet when betMaxEnabled is true**
  useEffect(() => {
    if (betMaxEnabled) {
      setBetAmount(maxBet);
    }
  }, [betMaxEnabled, maxBet]);

  // Memoized updateBalances function
  const updateBalances = useCallback(async () => {
    try {
      const [dealerBal, playerBal] = await Promise.all([
        fetchBalance(blackjackAddress, provider),
        fetchBalance(playerAddress, provider),
      ]);

      // Convert balances and escrow to float for subtraction
      const dealerBalFloat = parseFloat(dealerBal);
      const totalEscrowFloat = parseFloat(totalEscrow);

      // Ensure that totalEscrow is a valid number
      if (!isNaN(dealerBalFloat) && !isNaN(totalEscrowFloat)) {
        const adjustedDealerBal = parseFloat(
          (dealerBalFloat - totalEscrowFloat).toFixed(3)
        );

        setDealerBankroll(adjustedDealerBal);
      }
      setPlayerBankroll(playerBal);
    } catch (error) {
      console.error("Error updating balances:", error);
      addAlert({
        status: "error",
        title: "Balance Update Failed",
        description: "Unable to update balances at this time.",
      });
    }
  }, [playerAddress, addAlert, totalEscrow]);

  // Fetch balances on mount and when updateBalances changes
  useEffect(() => {
    updateBalances();
  }, [updateBalances]);

  // Function to add a placeholder card to a player's hand
  const addPlaceholderCardToHand = useCallback((handIndex) => {
    setAnimatedPlayerHands((prev) => {
      const updated = [...prev];
      updated[handIndex] = [...(updated[handIndex] || []), "??"];
      return updated;
    });
    setCardShown((prev) => {
      const updated = { ...prev };
      updated.player = [...(updated.player || [])];
      updated.player[handIndex] = [...(updated.player[handIndex] || []), false];
      return updated;
    });
    setLastFlippedCardIndex((prev) => ({
      ...prev,
      [handIndex]: (prev[handIndex] || 0) + 1,
    }));
  }, []);

  // Reset various UI states
  const resetShowStates = useCallback(() => {
    setShowGameStatus(false);
    setShowPayoutMessage(false);
    setShowLabel(false);
    setShowGameActions(false);
    setShowBetButton(false);
    console.log(
      "Resetting showGameStatus, showPayoutMessage, showLabel, showGameActions, showBetButton"
    );
    updateBalances();
  }, [updateBalances]);

  // Clear the game board
  const clearBoard = useCallback(() => {
    setConfettiRecycle(false);
    const initialPlayerHands = [[]];
    const initialDealerHand = [];
    setGameData({
      playerHands: initialPlayerHands,
      dealerHand: initialDealerHand,
      gameStarted: false,
      gameSettled: false,
      currentHandIndex: 0,
      betAmounts: [],
      handStatuses: [],
      payouts: [],
      handReasons: [],
    });
    setAnimatedPlayerHands(initialPlayerHands);
    setAnimatedDealerHand(initialDealerHand);
    setCardShown({
      player: initialPlayerHands.map((hand) => hand.map(() => false)),
      dealer: initialDealerHand.map(() => false),
    });
    cardShownRef.current = {
      player: initialPlayerHands.map((hand) => hand.map(() => false)),
      dealer: initialDealerHand.map(() => false),
    };
    lastFlippedCardIndexRef.current = {};
    resetShowStates();

    // Reset previousGameDataRef to allow initial deal for the next game
    previousGameDataRef.current = null;

    setNewColorScheme();
    updateBalances();
  }, [
    setGameData,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setNewColorScheme,
    updateBalances,
    resetShowStates,
  ]);

  // Define isCardInDesiredState at top level
  const isCardInDesiredState = useCallback(
    (handType, handIndex, cardIndex, faceDown) => {
      const desiredState = !faceDown;

      // Allow animation if the card is face-down ("??") and we expect it to be face-down
      const cardValue =
        handType === "player"
          ? cardShownRef.current.player?.[handIndex]?.[cardIndex]
          : cardShownRef.current.dealer?.[cardIndex];

      if (faceDown && cardValue !== "??") {
        // If the card is expected to be face-down but is not currently "??", we need animation
        return false;
      }

      if (!faceDown && cardValue === "??") {
        // If the card is supposed to be face-up but is currently "??", we need animation
        return false;
      }

      if (handType === "player") {
        return (
          cardShownRef.current.player &&
          cardShownRef.current.player[handIndex] &&
          cardShownRef.current.player[handIndex][cardIndex] === desiredState
        );
      } else if (handType === "dealer") {
        return (
          cardShownRef.current.dealer &&
          cardShownRef.current.dealer[cardIndex] === desiredState
        );
      }
      return false;
    },
    []
  );

  // Define animateAndRevealCard at top level
  const animateAndRevealCard = useCallback(
    async (handType, handIndex, cardIndex, cardValue, faceDown = false) => {
      // Check if the card is already in the desired state
      if (isCardInDesiredState(handType, handIndex, cardIndex, faceDown)) {
        console.log(
          `Card ${handType} ${
            handIndex !== null ? handIndex : ""
          } ${cardIndex} is already in desired state, skipping animation.`
        );
        return;
      }

      console.log(
        `Animating and revealing card: ${handType}, Hand: ${handIndex}, Card: ${cardIndex}, Value: ${cardValue}, FaceDown: ${faceDown}`
      );

      // Set placeholder (face-down card)
      if (handType === "player") {
        setAnimatedPlayerHands((prev) => {
          const updated = [...prev];
          if (!updated[handIndex]) updated[handIndex] = [];
          updated[handIndex][cardIndex] = "??"; // Placeholder for face-down card
          return updated;
        });
      } else if (handType === "dealer") {
        setAnimatedDealerHand((prev) => {
          const updated = [...prev];
          updated[cardIndex] = "??"; // Placeholder for face-down card
          return updated;
        });
      }

      await new Promise((resolve) => setTimeout(resolve, delay));

      // Reveal actual card only if it's face-up
      if (!faceDown) {
        if (handType === "player") {
          setAnimatedPlayerHands((prev) => {
            const updated = [...prev];
            updated[handIndex][cardIndex] = cardValue; // Reveal actual card
            return updated;
          });
        } else if (handType === "dealer") {
          setAnimatedDealerHand((prev) => {
            const updated = [...prev];
            updated[cardIndex] = cardValue; // Reveal actual card
            return updated;
          });
        }
      }

      await new Promise((resolve) => setTimeout(resolve, delay));

      // Update cardShown state
      setCardShown((prev) => {
        const updated = { ...prev };
        if (handType === "player") {
          if (!updated.player) updated.player = [];
          if (!updated.player[handIndex]) updated.player[handIndex] = [];
          updated.player[handIndex][cardIndex] = !faceDown;
        } else if (handType === "dealer") {
          if (!updated.dealer) updated.dealer = [];
          updated.dealer[cardIndex] = !faceDown;
        }
        console.log(
          `Card shown updated: ${handType}, Hand: ${handIndex}, Card: ${cardIndex}, faceDown: ${faceDown}`
        );
        return updated;
      });

      await new Promise((resolve) => setTimeout(resolve, delay));
    },
    [isCardInDesiredState, delay]
  );

  // Define handlePlayerHandUpdates at top level
  const handlePlayerHandUpdates = useCallback(async () => {
    const currentPlayerHands = gameData.playerHands || [];
    const previousPlayerHands = previousGameDataRef.current?.playerHands || [];

    for (
      let handIndex = 0;
      handIndex < currentPlayerHands.length;
      handIndex++
    ) {
      const currentHand = currentPlayerHands[handIndex];
      const previousHand = previousPlayerHands[handIndex] || [];

      for (let cardIndex = 0; cardIndex < currentHand.length; cardIndex++) {
        const currentCard = currentHand[cardIndex];
        const previousCard = previousHand[cardIndex];

        if (currentCard && currentCard !== previousCard) {
          if (!isCardInDesiredState("player", handIndex, cardIndex, false)) {
            await animateAndRevealCard(
              "player",
              handIndex,
              cardIndex,
              currentCard
            );
          }
        }
      }
    }
  }, [gameData.playerHands, animateAndRevealCard, isCardInDesiredState]);

  // Define detectSplit at top level
  const detectSplit = useCallback(() => {
    const previousHandCount =
      previousGameDataRef.current?.playerHands?.length || 1;
    const currentHandCount = gameData.playerHands?.length || 1;
    return currentHandCount > previousHandCount;
  }, [gameData.playerHands]);

  // Handle split-specific updates
  const handleSplit = useCallback(async () => {
    const previousHands = previousGameDataRef.current.playerHands || [];
    const newHands = gameData.playerHands.slice(previousHands.length);

    for (let i = 0; i < newHands.length; i++) {
      const handIndex = previousHands.length + i;
      const newHand = newHands[i];
      const firstCard = newHand[0];
      const secondCard = newHand[1];

      // Initialize animatedPlayerHands for the new hand
      setAnimatedPlayerHands((prev) => {
        const updated = [...prev];
        updated[handIndex] = [firstCard, "??"]; // Placeholder for second card
        return updated;
      });

      // Initialize cardShown for the new hand
      setCardShown((prev) => ({
        ...prev,
        player: [
          ...(prev.player || []),
          [true, false], // First card is already shown, second is face down
        ],
      }));

      // Only animate and reveal the second card
      if (secondCard) {
        await animateAndRevealCard("player", handIndex, 1, secondCard, false);
      }
    }
  }, [gameData.playerHands, animateAndRevealCard]);

  // **3. Modify handleBetAmountChange to disable Bet Max when user changes bet**
  const handleBetAmountChange = useCallback(
    (eOrValue) => {
      const value = eOrValue?.target?.value ?? eOrValue;
      setBetAmount(value);
      if (betMaxEnabled) {
        setBetMaxEnabled(false);
      }
    },
    [betMaxEnabled]
  );

  // Define handleGameUpdate using useCallback
  const handleGameUpdate = useCallback(async () => {
    try {
      const previousGameData = previousGameDataRef.current;

      // Initialize animatedDealerCards here
      const animatedDealerCards = new Set();

      // Main game update logic
      if (gameData.gameSettled) {
        console.log("Game is already settled. Handling end of game.");
        await handlePlayerHandUpdates();

        const dealerHand = gameData.dealerHand || [];
        for (let i = 0; i < dealerHand.length; i++) {
          const cardValue = dealerHand[i];
          // Force the card to be revealed regardless of its current state
          await animateAndRevealCard("dealer", null, i, cardValue, false);
          animatedDealerCards.add(`${i}-${cardValue}`);
        }

        await flipHandsSequentially("dealer");
        setShowGameStatus(true);
        setShowPayoutMessage(true);
        await updateBalances();
        setShowBetButton(true);
      } else if (
        gameData.gameStarted &&
        (!previousGameData || !previousGameData.gameStarted)
      ) {
        // New game has started
        setAnimatedPlayerHands([]);
        setAnimatedDealerHand([]);
        setCardShown(initializeCardShown());

        const initialDealOrder = [
          {
            handType: "player",
            handIndex: 0,
            cardIndex: 0,
            faceDown: false,
          },
          {
            handType: "dealer",
            handIndex: null,
            cardIndex: 0,
            faceDown: false,
          },
          {
            handType: "player",
            handIndex: 0,
            cardIndex: 1,
            faceDown: false,
          },
          {
            handType: "dealer",
            handIndex: null,
            cardIndex: 1,
            faceDown: true, // Dealer's second card is face-down
          },
        ];

        for (const deal of initialDealOrder) {
          const { handType, handIndex, cardIndex, faceDown } = deal;
          const cardValue =
            handType === "player"
              ? gameData.playerHands?.[handIndex]?.[cardIndex]
              : gameData.dealerHand?.[cardIndex];
          if (
            (cardValue &&
              !isCardInDesiredState(
                handType,
                handIndex,
                cardIndex,
                faceDown
              )) ||
            cardValue === "??"
          ) {
            await animateAndRevealCard(
              handType,
              handIndex,
              cardIndex,
              cardValue,
              faceDown
            );
            console.log("Initial deal card:", cardValue);
            if (handType === "dealer") {
              animatedDealerCards.add(`${cardIndex}-${cardValue}`);
            }
          }
        }

        const playerHands = gameData.playerHands || [];

        for (let handIndex = 0; handIndex < playerHands.length; handIndex++) {
          const playerHand = playerHands[handIndex] || [];

          // Determine starting card index based on hand
          const startCardIndex = handIndex === 0 ? 2 : 0;

          for (
            let cardIndex = startCardIndex;
            cardIndex < playerHand.length;
            cardIndex++
          ) {
            const cardValue = playerHand[cardIndex];

            if (
              cardValue &&
              !isCardInDesiredState("player", handIndex, cardIndex, false)
            ) {
              await animateAndRevealCard(
                "player",
                handIndex,
                cardIndex,
                cardValue,
                false
              );
            }
          }

          // Ensure the first card of additional hands is visible
          if (handIndex > 0 && playerHand.length > 0) {
            const firstCard = playerHand[0];
            if (
              firstCard &&
              !isCardInDesiredState("player", handIndex, 0, false)
            ) {
              // Set as shown without animation
              setAnimatedPlayerHands((prev) => {
                const updated = [...prev];
                if (!updated[handIndex]) updated[handIndex] = [];
                updated[handIndex][0] = firstCard; // Reveal actual card
                return updated;
              });

              setCardShown((prev) => {
                const updated = { ...prev };
                if (!updated.player) updated.player = [];
                if (!updated.player[handIndex]) updated.player[handIndex] = [];
                updated.player[handIndex][0] = true;
                return updated;
              });
            }
          }
        }

        console.log("Initial deal complete. Allowing player actions.");
        setShowGameActions(true);
        setShowBetButton(false);
      } else if (detectSplit()) {
        console.log("Split detected. Handling split.");
        await handleSplit();
      } else if (previousGameData) {
        console.log("Handling other game data updates.");

        await handlePlayerHandUpdates();

        const currentDealerHand = gameData.dealerHand || [];
        const previousDealerHand = previousGameData.dealerHand || [];

        for (let i = 0; i < currentDealerHand.length; i++) {
          const currentCard = currentDealerHand[i];
          const previousCard = previousDealerHand[i];

          if (
            currentCard &&
            currentCard !== previousCard &&
            !animatedDealerCards.has(`${i}-${currentCard}`)
          ) {
            if (!isCardInDesiredState("dealer", null, i, false)) {
              await animateAndRevealCard("dealer", null, i, currentCard, false);
              animatedDealerCards.add(`${i}-${currentCard}`);
            }
          }
        }

        if (gameData.gameStarted && !gameData.gameSettled) {
          setShowGameActions(true);
          setShowBetButton(false);
        }
      }

      // Update previousGameDataRef after handling
      previousGameDataRef.current = JSON.parse(JSON.stringify(gameData));
    } catch (error) {
      console.error("Error during game update:", error);
      addAlert({
        status: "error",
        title: "Game Update Error",
        description: "An error occurred while updating the game state.",
      });
    } finally {
      isUpdatingRef.current = false;
      if (needsUpdateRef.current) {
        needsUpdateRef.current = false;
        isUpdatingRef.current = true;
        await handleGameUpdate();
      }
    }
  }, [
    gameData,
    addAlert,
    flipHandsSequentially,
    initializeCardShown,
    updateBalances,
    handlePlayerHandUpdates,
    animateAndRevealCard,
    isCardInDesiredState,
    detectSplit,
    handleSplit,
    setShowGameActions,
    setShowBetButton,
  ]);

  // useEffect to trigger handleGameUpdate when gameData changes
  useEffect(() => {
    if (isUpdatingRef.current) {
      needsUpdateRef.current = true;
    } else {
      isUpdatingRef.current = true;
      handleGameUpdate();
    }
  }, [handleGameUpdate]);

  // Handle confetti for Blackjack
  useEffect(() => {
    if (gameData.handStatuses?.includes("BLACKJACK!") && !confettiRecycle) {
      setConfettiRunning(true);
      setConfettiRecycle(true);
    }
  }, [gameData.handStatuses, confettiRecycle]);

  // **4. Modify Betting component to pass betMaxEnabled and setBetMaxEnabled**
  const handleBetAmountChangeWithToggle = useCallback(
    (eOrValue) => {
      handleBetAmountChange(eOrValue);
    },
    [handleBetAmountChange]
  );

  // Start a new game
  const startNewGame = useCallback(async () => {
    setIsLoading(true);
    setLoadingAction("Placing Bet");
    if (parseFloat(betAmount) <= 0 || isNaN(betAmount)) {
      addAlert({
        status: "error",
        title: "Invalid Bet",
        description: "Please enter a valid bet amount greater than 0.",
      });
      setLoadingAction("");
      setIsLoading(false);
      await new Promise((resolve) => setTimeout(resolve, delay));
      updateBalances();
      return;
    }

    // Validate betAmount against maxBet
    if (parseFloat(betAmount) > parseFloat(maxBet)) {
      addAlert({
        status: "error",
        title: "Bet Exceeds Maximum",
        description: `The maximum allowed bet is ${maxBet} ETH.`,
      });
      setLoadingAction("");
      setIsLoading(false);
      await new Promise((resolve) => setTimeout(resolve, delay));
      updateBalances();
      return;
    }

    localStorage.setItem("betAmount", betAmount);
    clearBoard();
    await executeContractFunction({
      provider,
      playerAddress,
      setIsLoading,
      fetchGameData,
      addAlert,
      updateBalances,
      functionName: "startGame",
      value: ethers.parseEther(betAmount.toString()),
      setLoadingAction,
    });
  }, [
    betAmount,
    addAlert,
    playerAddress,
    fetchGameData,
    updateBalances,
    clearBoard,
    setIsLoading,
    maxBet,
  ]);

  // Handle player actions (hit, stand, etc.)
  const handleAction = useCallback(
    (actionFunction) => async () => {
      resetShowStates();
      const actionMap = {
        hit: "Hitting",
        stand: "Standing",
        doubleDown: "Doubling Down",
        split: "Splitting",
      };
      setLoadingAction(actionMap[actionFunction] || "Loading");
      console.log(`Handling action: ${actionFunction}`);

      if (actionFunction === "hit" || actionFunction === "doubleDown") {
        addPlaceholderCardToHand(gameData.currentHandIndex);
      }

      if (actionFunction === "split") {
        const [card1, card2] =
          gameData.playerHands[gameData.currentHandIndex] || [];
        setGameData((prev) => {
          const updated = { ...prev };
          updated.playerHands = [...(prev.playerHands || [])];
          updated.playerHands[gameData.currentHandIndex] = [card1, "??"];
          updated.playerHands.splice(gameData.currentHandIndex + 1, 0, [
            card2,
            "??",
          ]);
          return updated;
        });
        setAnimatedPlayerHands((prev) => {
          const updated = [...prev];
          updated[gameData.currentHandIndex] = [card1, "??"];
          updated.splice(gameData.currentHandIndex + 1, 0, [card2, "??"]);
          return updated;
        });
        setCardShown((prev) => {
          const updated = { ...prev };
          updated.player = [...(updated.player || [])];
          updated.player[gameData.currentHandIndex] = [true, false];
          updated.player.splice(gameData.currentHandIndex + 1, 0, [
            true,
            false,
          ]);
          return updated;
        });
        setLastFlippedCardIndex((prev) => ({
          ...prev,
          [gameData.currentHandIndex]: 0,
          [gameData.currentHandIndex + 1]: 0,
        }));
      }

      let value = null;
      if (actionFunction === "doubleDown" || actionFunction === "split") {
        const currentBet =
          gameData.betAmounts?.[gameData.currentHandIndex] || "0";
        value = ethers.parseEther(currentBet);
      }

      await executeContractFunction({
        provider,
        playerAddress,
        setIsLoading,
        fetchGameData,
        addAlert,
        updateBalances,
        functionName: actionFunction,
        functionArgs: [],
        value,
        setLoadingAction,
      });

      await fetchGameData();

      if (["hit", "doubleDown", "split"].includes(actionFunction)) {
        setCardShown((prev) => ({
          ...prev,
          player: (gameData.playerHands || []).map((hand, hIdx) =>
            (hand || []).map((card, cIdx) =>
              prev.player[hIdx] && prev.player[hIdx][cIdx] !== undefined
                ? prev.player[hIdx][cIdx]
                : false
            )
          ),
        }));
        await flipHandsSequentially("player");
      }

      setLoadingAction("");
    },
    [
      resetShowStates,
      addPlaceholderCardToHand,
      gameData.currentHandIndex,
      gameData.playerHands,
      gameData.betAmounts,
      playerAddress,
      setIsLoading,
      addAlert,
      fetchGameData,
      flipHandsSequentially,
      updateBalances,
      setGameData,
      setAnimatedPlayerHands,
      setCardShown,
      setLastFlippedCardIndex,
    ]
  );

  // Calculate current hand details
  const currentHand = gameData.playerHands?.[gameData.currentHandIndex] || [];
  const possibleHandValues = calculateHandValues(currentHand);
  const getCardValue = (card) =>
    card ? (card.length === 3 ? card.slice(0, 2) : card.slice(0, 1)) : "0";
  const canDoubleDown =
    currentHand.length === 2 &&
    possibleHandValues.some((val) => [9, 10, 11].includes(val)) &&
    !gameData.gameSettled;
  const canSplit =
    currentHand.length === 2 &&
    getCardValue(currentHand[0]) === getCardValue(currentHand[1]);

  // Handle account change
  const handleAccountChange = useCallback(
    (newAddress) => {
      clearBoard();
      setPlayerAddress(newAddress);
      localStorage.setItem("playerAddress", newAddress);
      fetchGameData();
    },
    [clearBoard, fetchGameData]
  );

  return (
    <Box w="100%" h="100%" p={fullscreen ? 0 : 3}>
      <Box
        w="100%"
        minH={fullscreen ? "100dvh" : "730px"}
        bg="radial-gradient(circle, #35654d 0%, #2f5a46 40%, #254c3a 100%)"
        borderColor="#1d3a2e"
        borderRadius={fullscreen ? "0" : "10px"}
        borderWidth={fullscreen ? 0 : 3}
        py={fullscreen ? 5 : 0}
        position="relative"
        align="center"
        justify="center"
        boxShadow="inset 0 0 15px rgba(0, 0, 0, 0.3), inset 0 0 3px rgba(255, 255, 255, 0.2)"
        alignContent="center"
        overflow="visible"
      >
        <IconButton
          icon={fullscreen ? <FaCompress /> : <FaExpand />}
          onClick={toggleFullscreen}
          aria-label="Toggle Fullscreen"
          colorScheme="#F9F6EE"
          size="lg"
          position="absolute"
          right={0}
          top={0}
          _hover={{ transform: "scale(1.05)" }}
          cursor="pointer"
          zIndex={1500}
        />
        {confettiRunning && (
          <Confetti
            run={confettiRunning}
            recycle={confettiRecycle}
            tweenDuration={10000}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        )}
        <Box overflowX="auto">
          <Box>
            <VStack>
              {!gameData.gameStarted && !isLoading ? (
                <Box h="530px">
                  <WelcomeScreen />
                </Box>
              ) : (
                <Box h="530px">
                  <Box h="210px">
                    <DealerHand
                      animatedDealerHand={animatedDealerHand}
                      colorScheme={colorScheme}
                      gameStarted={gameData.gameStarted}
                      ethAmount={dealerBankroll}
                    />
                  </Box>
                  <Box h="320px">
                    <PlayerHands
                      animatedPlayerHands={animatedPlayerHands}
                      colorScheme={colorScheme}
                      gameData={gameData}
                      showLabel={showLabel}
                      showGameActions={showGameActions}
                      isLoading={isLoading}
                      handleAction={handleAction}
                      canDoubleDown={canDoubleDown}
                      canSplit={canSplit}
                      loadingAction={loadingAction}
                      showGameStatus={showGameStatus}
                      showPayoutMessage={showPayoutMessage}
                    />
                  </Box>
                </Box>
              )}
              <Box h="70px">
                {showElements && (!gameData.gameStarted || showBetButton) && (
                  <Betting
                    isLoading={isLoading}
                    betAmount={betAmount}
                    handleBetAmountChange={handleBetAmountChangeWithToggle}
                    startNewGame={startNewGame}
                    loadingAction={loadingAction}
                    dealerBankroll={dealerBankroll}
                    maxBet={maxBet}
                    playerBalance={playerBankroll}
                    betMaxEnabled={betMaxEnabled}
                    setBetMaxEnabled={setBetMaxEnabled}
                  />
                )}
              </Box>
              <Box h="25px">
                {showElements && (
                  <Box>
                    <Text color="#F9F6EE" fontSize="lg">
                      Player:{" "}
                      {parseFloat(parseFloat(playerBankroll).toFixed(3))} ETH
                    </Text>
                  </Box>
                )}
              </Box>
              <Box h="50px">
                {showElements && (
                  <AccountSelector
                    currentAccount={playerAddress}
                    onAccountChange={handleAccountChange}
                    isDisabled={
                      isLoading || (gameData.gameStarted && !showBetButton)
                    }
                  />
                )}
              </Box>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Blackjack;
