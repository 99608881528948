// src/components/GameActions.js
import React from "react";
import { Button, HStack, VStack, Spinner, Text, Icon } from "@chakra-ui/react";
import {
  FaHandPaper,
  FaPlusCircle,
  FaHandPointUp,
  FaHandPeace,
} from "react-icons/fa";

const GameActions = ({
  isLoading,
  hit,
  stand,
  doubleDown,
  split,
  canDoubleDown,
  canSplit,
  gameStarted,
  gameSettled,
  showGameActions,
  currentHand,
  loadingAction,
}) => {
  if (!currentHand) {
    return null;
  }
  if (isLoading) {
    return (
      <HStack>
        <Spinner size="md" color="#F9F6EE" />
        <Text fontSize="xl" color="#F9F6EE">
          {loadingAction}...
        </Text>
      </HStack>
    );
  }
  if (!gameStarted || gameSettled) {
    return null;
  }
  if (showGameActions) {
    return (
      <VStack>
        <HStack>
          <Button
            onClick={stand}
            colorScheme="green"
            size="md"
            leftIcon={<Icon as={FaHandPaper} />}
            _hover={{ transform: "scale(1.05)" }}
            boxShadow="0px 5px 15px rgba(0,0,0,0.3)"
          >
            Stand
          </Button>
          <Button
            onClick={hit}
            colorScheme="blue"
            size="md"
            leftIcon={<Icon as={FaPlusCircle} />}
            _hover={{ transform: "scale(1.05)" }}
            boxShadow="0px 5px 15px rgba(0,0,0,0.3)"
          >
            Hit
          </Button>
        </HStack>
        {(canDoubleDown || canSplit) && (
          <HStack>
            {canSplit && (
              <Button
                onClick={split}
                colorScheme="purple"
                size="md"
                leftIcon={<Icon as={FaHandPeace} />}
                _hover={{ transform: "scale(1.05)" }}
                boxShadow="0px 5px 15px rgba(0,0,0,0.3)"
              >
                Split
              </Button>
            )}
            {canDoubleDown && (
              <Button
                onClick={doubleDown}
                colorScheme="teal"
                size="md"
                leftIcon={<Icon as={FaHandPointUp} />}
                _hover={{ transform: "scale(1.05)" }}
                boxShadow="0px 5px 15px rgba(0,0,0,0.3)"
              >
                Double Down
              </Button>
            )}
          </HStack>
        )}
      </VStack>
    );
  }
};

export default GameActions;
