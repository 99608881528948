import React, { useState, useEffect, createContext, useContext } from "react";
import { polygonAmoy, polygon } from "wagmi/chains";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createPublicClient, http } from "viem";

export const BlockchainContext = createContext();

const queryClient = new QueryClient();
const projectId = "bd3776f51a8c737a4552047daa594fcc";
const metadata = {
  name: "Blackjack",
  description: "Blackjack",
  url: "https://blackjack.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

export function Chain({ children }) {
  const determineChain = () => {
    const ispolygonAmoy = true;
    return ispolygonAmoy ? "polygonAmoy" : "polygon";
  };

  const [chain, setChain] = useState(determineChain());
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const selectedChain = chain === "polygon" ? polygon : polygonAmoy;
    const wagmiOptions = {
      publicClient: createPublicClient({
        chain: selectedChain,
        transport: http(),
      }),
    };
    const newConfig = defaultWagmiConfig({
      chains: [selectedChain],
      projectId,
      metadata,
      ...wagmiOptions,
    });
    createWeb3Modal({
      wagmiConfig: newConfig,
      projectId,
      enableAnalytics: true,
      enableOnramp: true,
    });
    setConfig(newConfig);
  }, [chain]);

  const value = {
    chain,
    setChain,
  };

  return (
    <BlockchainContext.Provider value={value}>
      {config && (
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </WagmiProvider>
      )}
    </BlockchainContext.Provider>
  );
}

export function useBlockchain() {
  return useContext(BlockchainContext);
}
