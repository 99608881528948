import React from "react";
import {
  Box,
  Link,
  Text,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  ListItem,
  Divider,
  Heading,
  List,
  ListIcon,
  Spacer,
} from "@chakra-ui/react";
import { ExternalLinkIcon, TimeIcon } from "@chakra-ui/icons";
import {
  FaLayerGroup,
  FaArrowsAltH,
  FaAngleDoubleUp,
  FaAngleDoubleDown,
  FaUserTie,
  FaDollarSign,
  FaChartLine,
  FaEthereum,
  FaCode,
  FaFlask,
} from "react-icons/fa";

const Instructions = () => {
  return (
    <Box color="#1f3c60" maxW="600px">
      <Accordion allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="center">
              <Heading size="sm" color="#1f3c60">
                What is Polygon Blackjack?
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Heading size="md" mb={3} align="center">
              Blackjack on the Polygon Blockchain
            </Heading>

            <Box mb={3} align="center">
              <List display="flex" justifyContent="center" alignItems="center">
                <ListItem display="flex" alignItems="center" mr={4}>
                  <ListIcon as={FaAngleDoubleUp} color="blue.500" />
                  <Text color="blue.500" fontWeight="bold">
                    Transparent Ruleset
                  </Text>
                </ListItem>
                <ListItem display="flex" alignItems="center" mr={4}>
                  <ListIcon as={FaUserTie} color="purple.500" />
                  <Text color="purple.500" fontWeight="bold">
                    Player-Friendly Odds
                  </Text>
                </ListItem>
                <ListItem display="flex" alignItems="center">
                  <ListIcon as={FaChartLine} color="green.500" />
                  <Text color="green.500" fontWeight="bold">
                    Dynamic Limits
                  </Text>
                </ListItem>
              </List>
            </Box>

            <Divider my={3} />

            <Heading size="sm" mb={3}>
              Gameplay
            </Heading>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={FaLayerGroup} color="blue.500" />
                <b>Single Deck:</b> One deck shuffled every hand.
              </ListItem>
              <ListItem>
                <ListIcon as={FaArrowsAltH} color="blue.500" />
                <b>Split any pairs:</b> Including aces, as many times as you'd
                like.
              </ListItem>
              <ListItem>
                <ListIcon as={FaAngleDoubleDown} color="blue.500" />
                <b>Double down on 9, 10, or 11:</b> Even after splitting.
              </ListItem>
              <ListItem>
                <ListIcon as={FaUserTie} color="purple.500" />
                <b>Dealer hits on soft 17</b>
              </ListItem>
              <ListItem>
                <ListIcon as={FaDollarSign} color="purple.500" />
                <b>Blackjack pays 3:2</b>
              </ListItem>
              <ListItem>
                <ListIcon as={FaChartLine} color="green.500" />
                <b>Max Bet:</b> A bet may be at most than one-tenth the dealer's
                bankroll. When a bet is placed, 8 times the bet amount is
                reserverd so the contract always pays out - even if you split
                four times and win a double down on each hand.
              </ListItem>
            </List>

            <Divider my={3} />

            <Heading size="sm" mb={3}>
              Fairness and Transparency
            </Heading>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={FaEthereum} color="teal.500" />
                <b>No Bank:</b> Hands are played directly against a{" "}
                <Link
                  color="blue.500"
                  href="https://soliditylang.org/"
                  isExternal
                >
                  Solidity <ExternalLinkIcon />
                </Link>{" "}
                smart contract.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCode} color="teal.500" />
                <b>Open Source:</b> View the smart contract code on{" "}
                <Link
                  color="blue.500"
                  href="https://polygonscan.com/"
                  isExternal
                >
                  PolygonScan <ExternalLinkIcon />
                </Link>{" "}
                (soon).
              </ListItem>
            </List>

            <Divider my={3} />

            <Heading size="sm" mb={3}>
              Additional Information
            </Heading>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={TimeIcon} color="orange.500" />
                <b>Inactivity Rule:</b> Games without an action taken for over
                24 hours may be automatically settled.
              </ListItem>
              <ListItem>
                <ListIcon as={FaFlask} color="orange.500" />
                <b>Just a Test:</b> This is a temporary test environment. Play
                for free, but accounts may be reset at any time.
              </ListItem>
            </List>

            <Spacer py={3} />

            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Box textAlign="center">
                    <Heading size="sm">How does it work?</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={3}>
                    This version operates on a locally hosted test instance of
                    the{" "}
                    <Link
                      color="blue.500"
                      href="https://polygon.technology/"
                      isExternal
                    >
                      Polygon Blockchain <ExternalLinkIcon />
                    </Link>
                    . The game state is stored directly on the blockchain.
                  </Text>
                  <Text mb={3}>
                    The game is played against the smart contract on the
                    blockchain. All transactions are directly between the
                    player's address and the smart contract.
                  </Text>
                  <Text mb={3}>
                    The maximum bet is 1/10th the dealer's current bankroll.
                    When a bet is placed, 10 times that amount is reserved,
                    ensuring pay out any possible win - including doubling down
                    after splitting.
                  </Text>
                  <Text mb={3}>
                    In order to keep the game flowing for all players, if a hand
                    lasts longer than 24 hours it may be automatically forfeit
                    as a dealer win.
                  </Text>
                  <Text mb={3}>Good luck!</Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default Instructions;
