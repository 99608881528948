// src/components/WelcomeScreen.js
import React, { useState, useEffect } from "react";
import { VStack, Box, Text, Flex, useMediaQuery } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import Card from "./Card";
import useColorScheme from "../hooks/useColorScheme";

const WelcomeScreen = () => {
  const [animatedCards, setAnimatedCards] = useState([]);
  const [colorScheme, setNewColorScheme] = useColorScheme();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const [showWelcome, setShowWelcome] = useState(false);
  const [showBetMessage, setShowBetMessage] = useState(false);

  const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `;

  const fadeInOut = keyframes`
    0% { opacity: 0; transform: scale(0.8); }
    50% { opacity: 1; transform: scale(1); }
    100% { opacity: 0; transform: scale(0.8); }
  `;

  const shimmerGold = keyframes`
    100%, 80%, 100% { color: white; }
    90% { color: #fff9d6; } /* Soft gold at the midpoint */
  `;

  // Helper function to map suit letters to symbols
  const suitSymbols = {
    S: "♠",
    H: "♥",
    D: "♦",
    C: "♣",
  };

  // Function to parse card strings into rank and suit
  const parseCard = (cardStr) => {
    if (cardStr === "??") {
      return { rank: "?", suit: "?" };
    }
    const match = cardStr.match(/^([0-9]{1,2}|[AJQK])([SHDC])$/);
    if (!match) return { rank: "?", suit: "?" };
    const [, rank, suitLetter] = match;
    const suit = suitSymbols[suitLetter] || "?";
    return { rank, suit };
  };

  useEffect(() => {
    const allCards = [
      "AS",
      "2S",
      "3S",
      "4S",
      "5S",
      "6S",
      "7S",
      "8S",
      "9S",
      "10S",
      "JS",
      "QS",
      "KS",
      "AH",
      "2H",
      "3H",
      "4H",
      "5H",
      "6H",
      "7H",
      "8H",
      "9H",
      "10H",
      "JH",
      "QH",
      "KH",
      "AD",
      "2D",
      "3D",
      "4D",
      "5D",
      "6D",
      "7D",
      "8D",
      "9D",
      "10D",
      "JD",
      "QD",
      "KD",
      "AC",
      "2C",
      "3C",
      "4C",
      "5C",
      "6C",
      "7C",
      "8C",
      "9C",
      "10C",
      "JC",
      "QC",
      "KC",
    ];

    let timeoutId1, timeoutId2;

    const flipCards = () => {
      const newCard1 = allCards[Math.floor(Math.random() * allCards.length)];
      const newCard2 = allCards[Math.floor(Math.random() * allCards.length)];

      setNewColorScheme();
      setAnimatedCards([{ value: "??" }, { value: "??" }]);

      timeoutId1 = setTimeout(() => {
        setAnimatedCards([{ value: newCard1 }, { value: newCard2 }]);

        timeoutId2 = setTimeout(flipCards, 2500);
      }, 2500);
    };

    flipCards();

    setTimeout(() => setShowWelcome(true), 500);
    setTimeout(() => setShowBetMessage(true), 1500);

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, [setNewColorScheme]);

  return (
    <VStack spacing={8} px={8} mt="110px">
      <Box h="40px">
        {showWelcome && (
          <Text
            fontSize={isLargerThan800 ? "3xl" : "lg"}
            color="white"
            textAlign="center"
            fontWeight={700}
            textShadow="2px 2px 5px rgba(0, 0, 0, 0.7)"
            animation={`${fadeIn} 2s ease-in forwards, ${shimmerGold} 8s ease-in-out infinite`}
          >
            WELCOME TO POLYGON BLACKJACK
          </Text>
        )}
      </Box>
      <Box h="20px">
        {showBetMessage && (
          <Text
            fontSize={isLargerThan800 ? "xl" : "md"}
            color="white"
            textAlign="center"
            textShadow="2px 2px 5px rgba(0, 0, 0, 0.7)"
            animation={`${fadeIn} 4s ease-in forwards`}
          >
            place a bet to begin
          </Text>
        )}
      </Box>
      <Box p={5}>
        <Flex justify="center" align="center">
          {animatedCards.map((card, index) => {
            const { rank, suit } = parseCard(card.value);
            return (
              <Box
                key={index}
                animation={`${fadeInOut} 5s ease-in-out infinite`}
                mx={2}
              >
                <Card rank={rank} suit={suit} colorScheme={colorScheme} />
              </Box>
            );
          })}
        </Flex>
      </Box>
    </VStack>
  );
};

export default WelcomeScreen;
