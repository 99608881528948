import React from "react";
import { Heading, useBreakpointValue, Image, Box } from "@chakra-ui/react";
//import { ConnectKitButton } from "connectkit";

const Header = () => {
  const outerDirection = useBreakpointValue({ base: "column", sm: "row" });
  const innerDirection = outerDirection === "row" ? "row" : "column";

  return (
    <Box
      backgroundColor="rgba(162, 181, 197, 0.5)"
      align="center"
      direction={innerDirection}
      color="#1f3c60"
    >
      <Box align="left" width="24px" pt={3}>
        <Image src="/logo.svg" />
      </Box>
      <Heading size="lg" p={3}>
        Polygon Blackjack
      </Heading>
    </Box>
  );
};

export default Header;
