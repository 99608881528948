// src/utils/ethersUtils.js

import { ethers } from "ethers";
import BlackjackABI from "../BlackjackABI.json";
import { blackjackAddress } from "../constants";
import { accounts } from "../constants/accounts"; // Import the accounts

/**
 * Retrieves the signer corresponding to the provided address.
 *
 * @param {string} address - The Ethereum address of the player.
 * @param {ethers.providers.Provider} provider - The ethers provider.
 * @returns {ethers.Wallet} - The signer associated with the address.
 */
export const getSigner = (address, provider) => {
  const account = accounts.find(
    (acc) => acc.address.toLowerCase() === address.toLowerCase()
  );
  if (!account) {
    throw new Error(`Account with address ${address} not found.`);
  }
  const privateKey = account.privateKey;
  if (!privateKey) {
    throw new Error(`Private key for address ${address} not found.`);
  }
  return new ethers.Wallet(privateKey, provider);
};

/**
 * Initializes the contract with the provided signer.
 *
 * @param {ethers.Wallet} signer - The signer to interact with the contract.
 * @returns {ethers.Contract} - The initialized contract instance.
 */
export const getContract = (signer) =>
  new ethers.Contract(blackjackAddress, BlackjackABI, signer);

/**
 * Fetches the balance of the specified address.
 *
 * @param {string} address - The Ethereum address.
 * @param {ethers.providers.Provider} provider - The ethers provider.
 * @returns {Promise<string>} - The balance in Ether.
 */
export const fetchBalance = async (address, provider) => {
  try {
    const balance = await provider.getBalance(address);
    return ethers.formatEther(balance);
  } catch (err) {
    console.log("Error fetching balance:", err);
    return "0";
  }
};

/**
 * Executes a contract function with retry logic.
 *
 * @param {Object} params - The parameters for executing the function.
 * @param {ethers.providers.Provider} params.provider - The ethers provider.
 * @param {string} params.playerAddress - The address of the player.
 * @param {Function} params.setIsLoading - Function to set loading state.
 * @param {Function} params.fetchGameData - Function to fetch game data.
 * @param {Function} params.addAlert - Function to add alerts.
 * @param {Function} params.updateBalances - Function to update balances.
 * @param {string} params.functionName - The name of the contract function to call.
 * @param {Array} [params.functionArgs=[]] - Arguments for the contract function.
 * @param {string|null} [params.value=null] - Ether value to send with the transaction.
 * @param {Function} params.setLoadingAction - Function to set the loading action.
 * @param {number} [params.maxRetries=10] - Maximum number of retry attempts.
 */
export const executeContractFunction = async ({
  provider,
  playerAddress,
  setIsLoading,
  fetchGameData,
  addAlert,
  updateBalances,
  functionName,
  functionArgs = [],
  value = null,
  setLoadingAction,
  maxRetries = 5,
}) => {
  setIsLoading(true);
  let attempts = 0;
  let success = false;
  let lastError = null;

  let signer;
  try {
    signer = getSigner(playerAddress, provider);
  } catch (error) {
    console.error("Error initializing signer:", error);
    addAlert({
      status: "error",
      title: "Signer Initialization Failed",
      description: error.message,
    });
    setIsLoading(false);
    return;
  }

  const contract = getContract(signer);

  while (attempts < maxRetries && !success) {
    try {
      attempts += 1;

      const tx = value
        ? await contract[functionName](...functionArgs, { value })
        : await contract[functionName](...functionArgs);
      await tx.wait();

      await fetchGameData();
      await updateBalances();
      success = true;
    } catch (err) {
      lastError = err;
      console.error(`Attempt ${attempts} failed:`, err);
      if (err.code === "INSUFFICIENT_FUNDS" || err.code === "CALL_EXCEPTION") {
        break;
      }
      const delay = Math.pow(2, attempts) * 1000;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  if (!success) {
    // Extract the reason from the error message if it exists
    const reasonMatch = lastError.message.match(/reason="([^"]+)"/);
    const reason = reasonMatch ? reasonMatch[1] : "An unknown error occurred";

    addAlert({
      status: "error",
      title: `Error during ${functionName}`,
      description: `Reason: ${reason}`,
    });

    console.error(`All ${attempts} attempts failed.`);
    console.error(lastError);
  }

  setLoadingAction("");
  setIsLoading(false);
};
